// data.js
import React from 'react';

const dataFaq = [
    {
        id: 4,
        title: 'Programme de la fête',
        content: (
            <>
                <p>
                    <strong>Dès 15h</strong> : Accueil des sociétés
                </p>
                <p>
                    <strong>Dès 16h</strong> : Grand Cortège direction l’évouette
                </p>
                <p>Ouverture du bar extérieur</p>
                <p>Carrousel de Guggen</p>
                <p>
                    <strong>Dès 18h</strong> : Restauration dans les commerces du village
                </p>
                <p>Production des sociétés devant les commerces</p>
                <p>
                    <strong>Dès 20h</strong> : Cortège de nuit direction la place de fête
                </p>
                <p>
                    <strong>21h</strong> : Production des sociétés
                </p>
                <p>
                    <strong>00h</strong> : Bal sur la place de l’église
                </p>
            </>
        ),
    },
    {
        id: 5,
        title: 'Transport et sécurité',
        content: (
            <>
                <p>
                    Afin de rentrer chez soi en toute sécurité, le lunbabus est mis gratuitement à disposition.
                </p>
                <p>
                    <strong>Départ :</strong> de Saint-Martin à 02h00 :
                </p>
                <ul>
                    <li>Saint-Martin église</li>
                    <li>Evolène</li>
                    <li>Hérémence</li>
                    <li>Vex</li>
                    <li>Sion</li>
                </ul>
            </>
        ),
    },
];

export default dataFaq;
